// Login page Animation
export const isLoginPageAnimationEnabled = true;


// Login Page Style
export const isDefaultStyle = true; // true or false


//Images
export const logoPath = "rgcplogo.png";


//Pendo Analytics
export const pendoKey = '055e0a8b-b58d-43b8-6bc3-991ad9ea3974';
export const studyURL = "ascent.longboat.com";
export const sponsorName = "REGENXBIO";
export const studyId = "REGX281";
export const AppType = "CN";
export const pendoAccountName = studyId + '_' + AppType;

// Authentication
export const cookieExpiryTimeInMins = 10
export const passwords = [{
        key: "pass", // key used for storing this password in cookie
        value: "REGXCN!", // password
        toRoute: "/", // go to this route/url after successful authentication
    },
    {
        key: "passRev",
        value: "REGX281CN?",
        toRoute: "amend",
    },
];


// i18n localisation configuration
export const defaultLocale = "en";
export const supportedLocales = [
    "en",
    "fc",
    "sp"
];